import React from "react";
import { TechnicalProjectsData } from "./ProjectsItemData";
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import { CgLivePhoto } from "react-icons/cg";
import { HiCode } from "react-icons/hi";
import UndrawPhotoAndTreeRed from "../assets/redundantSVGs/UndrawPhotoAndTreeRed.svg"
import UndrawPhotoAndTreeYellow from "../assets/redundantSVGs/UndrawPhotoAndTreeYellow.svg"
import UndrawToTheStarsRed from "../assets/redundantSVGs/UndrawToTheStarsRed.svg"
import UndrawToTheStarsYellow from "../assets/redundantSVGs/UndrawToTheStarsYellow.svg"

function TechnicalProjects({toggleState, ObjectSVG}) {
  const ForATag = (Ahref, Icon, SpanText) => {
    return (
      <a href={Ahref} target="_blank" rel="noreferrer">
        <button className="singleProject--btn">
          <div className="centerDiv--singleProjectIcon">
            <Icon className="singleProject--icon" />
            <span>{SpanText}</span>
          </div>
        </button>
      </a>
    )
  }
  return (
    <div className="projects--technicalContainer" id="technical">
      {TechnicalProjectsData.map((ProjectItem) => (
        <>
          <div className="singleProject--technicalContainer">
            <Zoom>
              <img
                src={ProjectItem.image}
                id="projectImage--ID"
                className="projectImage"
                alt={ProjectItem.title}
              />
            </Zoom>
            <h2>{ProjectItem.title}</h2>
            {ForATag(ProjectItem.live, CgLivePhoto, "Live Demo")}
            {ForATag(ProjectItem.code, HiCode, "GitHub Link")}
          </div>
        </>
      ))}
      {
        toggleState ? 
          <>
          {ObjectSVG(UndrawToTheStarsYellow, 'redundantSVG2')}
          {ObjectSVG(UndrawPhotoAndTreeYellow, 'redundantSVG4')}
          </>
          : 
          <>
          {ObjectSVG(UndrawToTheStarsRed, "redundantSVG2")}
          {ObjectSVG(UndrawPhotoAndTreeRed, "redundantSVG4")}
          </>
      }
    </div>
  );
}

export default TechnicalProjects;
