import React from "react";
import {
  IoLogoHtml5,
  IoLogoCss3,
  IoLogoJavascript,
  IoLogoSass,
} from "react-icons/io5";
import { FaReact, FaBootstrap, FaGithub, FaNpm } from "react-icons/fa";
import {
  SiFigma,
  SiVisualstudiocode,
  SiNextdotjs,
  SiStyledcomponents,
  SiTypescript,
  SiAdobecreativecloud,
  SiAdobephotoshop,
  SiAdobepremierepro,
  SiAbletonlive,
  SiCanva,
} from "react-icons/si";
import { GoGear } from "react-icons/go";
import { GiLotus } from "react-icons/gi";
import { BsFillCaretDownFill, BsFillCaretUpFill } from "react-icons/bs";
import licenses from "../assets/LicensesAndCertifications-SanjayKarki.pdf";
import UndrawHappyMusicRed from "../assets/redundantSVGs/UndrawHappyMusicRed.svg";
import UndrawHappyMusicYellow from "../assets/redundantSVGs/UndrawHappyMusicYellow.svg";
import UndrawHackerMindRed from "../assets/redundantSVGs/UndrawHackerMindRed.svg";
import UndrawHackerMindYellow from "../assets/redundantSVGs/UndrawHackerMindYellow.svg";

function Skills({ toggleState, ObjectSVG }) {
  const technicalIcons = [
    { icon: IoLogoHtml5, iconTitle: "HTML" },
    { icon: IoLogoCss3, iconTitle: "CSS" },
    { icon: IoLogoJavascript, iconTitle: "JavaScript" },
    { icon: FaReact, iconTitle: "React" },
    { icon: SiNextdotjs, iconTitle: "Next.js" },
    { icon: SiTypescript, iconTitle: "TypeScript" },
    { icon: IoLogoSass, iconTitle: "Sass" },
    { icon: SiStyledcomponents, iconTitle: "Styled Components" },
    { icon: FaBootstrap, iconTitle: "Bootstrap" },
    { icon: FaGithub, iconTitle: "GitHub" },
    { icon: FaNpm, iconTitle: "npm" },
    { icon: SiVisualstudiocode, iconTitle: "Visual Studio Code" },
  ];
  const creativeIcons = [
    { icon: SiAdobephotoshop, iconTitle: "Adobe Photoshop" },
    { icon: SiFigma, iconTitle: "Figma" },
    { icon: SiAdobepremierepro, iconTitle: "Adobe Premiere Pro" },
    { icon: SiAbletonlive, iconTitle: "Abelton Live" },
    { icon: SiCanva, iconTitle: "Canva" },
    { icon: SiAdobecreativecloud, iconTitle: "Adobe Creative Cloud Express" },
  ];
  const [dispPDF, setDispPDF] = React.useState(false);

  return (
    <section className="skills--container">
      <div className="skills--technicalClass">
        <div className="centered--skills-sectionTitle">
          <h1 className="skills--sectionTitle">WEB</h1>
          <GoGear className="skills--sectionIcon" />
        </div>
        <p>
          As a self-taught web developer, I have a strong foundation in{" "}
          <span>front-end</span> technologies including <span>HTML</span>,{" "}
          <span>CSS</span>, and <span>JavaScript</span>, as well as a working
          knowledge of <span>UI/UX design</span> principles and best practices.{" "}
          <br />
          <br /> I prefer to use <span>React</span> as the front-end library for
          my projects, and I've only recently begun using the{" "}
          <span>Next.js</span> web application framework. For styling, I like using{" "}
          <span>Sass</span> to make my works look a tad bit
          prettier, but I also occasionally use <span>Bootstrap</span> and{" "}
          <span>Styled Components</span>. Currently, along with{" "}
          <span>TypeScript</span>, which I am somewhat familiar with, I am
          learning <span>Node.js</span>, <span>Express.js</span>, and other
          back-end development tools to gain some insights into{" "}
          <span>back-end</span> development. My go-to skills, tools, and
          technological resources are:
        </p>
        <div>
          {technicalIcons.map((EachIcon) => {
            const Icon = EachIcon.icon;
            const Title = EachIcon.iconTitle;
            return <Icon title={Title} className="skill--reactIcon" />;
          })}
        </div>
      </div>
      <div className="skills--creativeClass">
        <div className="centered--skills-sectionTitle">
          <h1 className="skills--sectionTitle">CREATIVE</h1>
          <GiLotus className="skills--sectionIcon" />
        </div>
        <p>
          When I'm not studying, coding or developing, I like to spend my time
          doing "creative" stuff. I particularly enjoy making music in my free
          time using software like <span>Ableton Live</span> &{" "}
          <span>FL Studio</span>.<br />
          <br /> Creating pleasing-to-the-eye visuals & graphic designs using{" "}
          <span>Canva</span>, <span>Adobe Photoshop</span>,{" "}
          <span>Sketchbook</span>, <span>VistaCreate</span>, etc. is another
          hobby of mine along with making simple video edits here and there
          using <span>Adobe Premiere Pro</span> (and sometimes{" "}
          <span>Vegas Pro</span>
          ).
        </p>
        <div>
          {creativeIcons.map((EachIcon) => {
            const Icon = EachIcon.icon;
            const Title = EachIcon.iconTitle;
            return <Icon title={Title} className="skill--reactIcon" />;
          })}
        </div>
      </div>
      {toggleState ? (
        <>
          {ObjectSVG(UndrawHackerMindYellow, "redundantSVG4")}
          {ObjectSVG(UndrawHappyMusicYellow, "redundantSVG3")}
        </>
      ) : (
        <>
          {ObjectSVG(UndrawHackerMindRed, "redundantSVG4")}
          {ObjectSVG(UndrawHappyMusicRed, "redundantSVG3")}
        </>
      )}
      <div id="skills--pdfContainer">
        <button
          className="skills--licensesPdfBtn"
          onClick={() => {
            setDispPDF((prevState) => !prevState);
          }}
        >
          {dispPDF ? (
            <div>
              <span>Licenses & Certifications</span>
              <BsFillCaretUpFill />
            </div>
          ) : (
            <div>
              <span>Some Relevant Certifications</span>
              <BsFillCaretDownFill />
            </div>
          )}
        </button>
        {dispPDF && (
          <iframe
            id="pdfIframe"
            src={`${licenses}#view=fitH`}
            title="Certifications"
            height="500px"
            width="100%"
            loading="lazy"
          />
        )}
      </div>
    </section>
  );
}

export default Skills;
