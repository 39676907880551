import React from "react";
import emailjs from '@emailjs/browser';
import contactLight from "../assets/contactLight.png";
import contactDark from "../assets/contactDark.png";
import { RiChatSmile3Fill } from "react-icons/ri";
import { IoSend } from "react-icons/io5";
import { FcOk } from "react-icons/fc";
import UndrawMailboxRed from "../assets/redundantSVGs/UndrawMailboxRed.svg"
import UndrawMailboxYellow from "../assets/redundantSVGs/UndrawMailboxYellow.svg"

function Contact({toggleState, ObjectSVG}) {
  const initialFormDetails = {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    message: "",
  };
  const onFormChange = (category, value) => {
    setFormDetails((prevFromDetails)=>({...prevFromDetails, [category]: value}))
  }
  // window.onbeforeunload = () => {
  //   for(const form of document.getElementsByTagName('form')) {
  //     form.reset();
  //   }
  // }
  const [formDetails, setFormDetails] = React.useState(initialFormDetails);
  const [buttonText, setButtonText] = React.useState('Send');
  const [isSendSuccess, setIsSendSuccess] = React.useState(false);

  // FOR EMAIL JS
  const form = React.useRef();
  const sendEmail = (e) => {
    e.preventDefault();
    setButtonText("Sending");
    emailjs.sendForm('service_etlfqi1', 'template_vc69x3o', form.current, 'Hc79Y20_UDbgV9Oyr')
      .then((result) => {
          setIsSendSuccess(true);
          setButtonText("Send");
          setFormDetails(initialFormDetails);
      }, (error) => {
          console.log(error.text);
      });
  };

  return (
    <>
    <section className={isSendSuccess ? "contact--container-lowOpacity" : "contact--container"}>
      <div className="contact--imgContainer">
        <img src={toggleState ? contactDark : contactLight} alt="[PNG] Contact Me" className="contact--png"></img>
      </div>
      <div className="contact--formContainer">
        <div className="centered--contact-sectionTitle">
          <h1>Get in touch</h1>
          <RiChatSmile3Fill className="contact--messageIcon" />
        </div>
        <form className="contact--form" ref={form} onSubmit={sendEmail}>
          <div className="contact--formFieldContainer">
            <input name="Name" className="contact--formField" type='text' maxlength="30" placeholder="First Name *" value={formDetails.firstName} onChange={(e)=> onFormChange('firstName' , e.target.value)} required />
            <input name="Surname" className="contact--formField" type='text' maxlength="30" placeholder="Last Name *" value={formDetails.lastName} onChange={(e)=> onFormChange('lastName' , e.target.value)} required />
          </div>
          <div className="contact--formFieldContainer">
            <input name="Email" className="contact--formField" type='email' maxlength="50" placeholder="Email Address *" value={formDetails.email} onChange={(e)=> onFormChange('email' , e.target.value)} required />
            <input name="Phone" className="contact--formField" type='number' placeholder="Contact No." value={formDetails.phone} onChange={(e)=> onFormChange('phone' , e.target.value)}  />
          </div>
          <div className="contact--formFieldContainer">
            <textarea name="Message" className="contact--formField" rows="7" minlength="7" maxlength="1200" placeholder="Message *" value={formDetails.message} onChange={(e)=> onFormChange('message', e.target.value)} required ></textarea>
          </div>
          <button type="submit" className="contact--submitBtn"><div className="send--iconContainer">{buttonText}<IoSend /></div></button>
        </form>
      </div>
      {
        toggleState ? 
          <>
          {ObjectSVG(UndrawMailboxYellow, 'redundantSVG2')}
          </>
          : 
          <>
          {ObjectSVG(UndrawMailboxRed, "redundantSVG2")}
          </>
      }
    </section>
    {isSendSuccess && 
      <div className="contact--sendSuccessPopUpDiv">
        <FcOk className="contact--iconOK" />
        <h2>Message sent successfully!</h2>
        <button className="sendSuccessBtn" onClick={() => {setIsSendSuccess(false)}}>Got it</button>
      </div>
    }
    </>
  );
}

export default Contact;