import React from "react";
import saanuLOL from "../assets/saanuB&W-cropped.svg";
import { motion } from "framer-motion";
import Typical from "react-typical";
import { ImLinkedin } from "react-icons/im";

function Home({toggleState}) {
  // DYNAMIC TEXT REPLACEMENT
  const dynamicTextLoad = () => {
    let i = 0;

    const randomizeText = () => {
      const phrase = document.querySelector(".dynamic--words");

      const phrases = [
        "LOVE anime/manga",
        "make music (sometimes)",
        "love travelling",
        "prefer text/email",
        "am detail-oriented",
        "am thorough",
        "love graphic design",
        "prefer remote/hybrid",
        "am a perfectionist",
        "like cats (& doggy dogs)",
        "fancy cycling",
        "like learning new languages",
        "like computing",
        "like aesthetic designs",
        "like simplistic web designs",
        "welcome new challenges",
        "am web-taught",
        "am new to web development",
        "believe in CSS supremacy",
        "am hungry for knowledge :P",
        "am introverted",
      ];

      i = randomNum(i, phrases.length);
      const newPhrase = phrases[i];
      phrase.textContent = newPhrase;
      // setTimeout(() => {
      //   phrase.textContent = newPhrase;
      // }, 300); // time to allow opacity to hit 0 before changing word
    };

    const randomNum = (num, max) => {
      let j = Math.floor(Math.random() * max);

      // ensure diff num every time
      if (num === j) {
        return randomNum(i, max);
      } else {
        return j;
      }
    };

    const txts=document.querySelector(".animate-text").children, txtsLen=txts.length;
    let index=0;
    const textInTimer=3000, textOutTimer=2800;

    const animateText= () => {
      for(let x=0; x<txtsLen; x++){
        txts[x].classList.remove("text-in","text-out");  
      }

      txts[index].classList.add("text-in");

      setTimeout(()=>{
        txts[index].classList.add("text-out");
      },textOutTimer)

      setTimeout(()=>{
        if(index === txtsLen-1){
          index=0;
        }
        else{
          index++;
        }
        randomizeText();  
        animateText();
      },textInTimer); 
    }

    animateText();
    // window.onload=animateText;
  };

  return (
      <hero onLoad={() => dynamicTextLoad()} className="home--container">
        <section className="home--heroText">
          <h1>
            I'M SANJAY&nbsp;&nbsp;
            <text>
              <Typical
                steps={[
                  "",
                  3000,
                  "aka ",
                  1500,
                  "aka Saanu",
                  3000,
                ]}
                loop={Infinity}
                wrapper="span"
                className="typical--heroText"
              />
            </text>
          </h1>
          <p>
            A <span className="hero--spanCompEngg">developer</span> based in <text title="Nepal">🇳🇵</text><br /><br />
            I like working on the front end of the web and am also keenly interested in social media marketing & digital marketing in general.
            <br />
            <br />
            {"I"}&nbsp;<text className="animate-text"><span className="dynamic--words text-in">am new to web development</span></text>
          </p>
          <a
            href="https://www.linkedin.com/in/sanjay-karki9/"
            target="_blank"
            rel="noreferrer"
          >
            <button className="home--heroButton">
              <div className="center--heroLinkedin">
                <ImLinkedin className="linkedin--icon" />
                <span>Connect with me</span>
              </div>
            </button>
          </a>
        </section>
        <section className="home--heroImage">
          <object className="hero--img" data={saanuLOL} type="image/svg+xml" aria-label="[SVG] My avatar LOL">
            <img src={saanuLOL} alt="[SVG] My avatar LOL" className="hero--img" />
          </object>
        </section>
        <svg
          class="blob-motion"
          id="visual"
          viewBox="0 0 960 540"
          width="960"
          height="540"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          version="1.1"
        >
          {/* <g transform="translate(484.9331549507159 276.29401477025283)"> */}
          <g transform="translate(730 276.29401477025283)">
            <motion.path
              id="blob1"
              d="M159.1 -141.6C199.5 -118.8 220.7 -59.4 211.4 -9.3C202.1 40.8 162.2 81.6 121.9 107.7C81.6 133.9 40.8 145.4 4.5 141C-31.8 136.5 -63.6 116 -108.6 89.8C-153.6 63.6 -211.8 31.8 -221.8 -10C-231.9 -51.9 -193.7 -103.7 -148.7 -126.5C-103.7 -149.4 -51.9 -143.2 3.8 -147C59.4 -150.7 118.8 -164.5 159.1 -141.6"
              fill={toggleState ? "#FCE100" : "#c41437"}
              animate={{
                d: [
                  "M159.1 -141.6C199.5 -118.8 220.7 -59.4 211.4 -9.3C202.1 40.8 162.2 81.6 121.9 107.7C81.6 133.9 40.8 145.4 4.5 141C-31.8 136.5 -63.6 116 -108.6 89.8C-153.6 63.6 -211.8 31.8 -221.8 -10C-231.9 -51.9 -193.7 -103.7 -148.7 -126.5C-103.7 -149.4 -51.9 -143.2 3.8 -147C59.4 -150.7 118.8 -164.5 159.1 -141.6",
                  "M110 -106.7C151.2 -68.8 199.1 -34.4 209.6 10.5C220.1 55.4 193.1 110.8 151.9 155.8C110.8 200.8 55.4 235.4 11.8 223.6C-31.8 211.8 -63.6 153.6 -108.3 108.6C-153 63.6 -210.5 31.8 -224.5 -14C-238.5 -59.9 -209.1 -119.7 -164.4 -157.6C-119.7 -195.4 -59.9 -211.2 -12.7 -198.5C34.4 -185.7 68.8 -144.5 110 -106.7",
                  "M152.1 -150.7C192.4 -111.7 217.2 -55.9 206 -11.2C194.8 33.5 147.6 66.9 107.3 111.9C66.9 156.9 33.5 213.5 0.8 212.6C-31.8 211.8 -63.6 153.6 -89.5 108.6C-115.3 63.6 -135.2 31.8 -140.6 -5.4C-146 -42.7 -137 -85.3 -111.2 -124.3C-85.3 -163.3 -42.7 -198.7 6.6 -205.3C55.9 -211.9 111.7 -189.7 152.1 -150.7",
                  "M104.6 -88.8C145.6 -63.6 195.8 -31.8 199.8 4C203.8 39.8 161.7 79.7 120.7 105.8C79.7 132 39.8 144.5 -7.9 152.4C-55.6 160.3 -111.3 163.6 -156.3 137.4C-201.3 111.3 -235.6 55.6 -232.9 2.7C-230.2 -50.2 -190.4 -100.4 -145.4 -125.6C-100.4 -150.7 -50.2 -150.9 -9.2 -141.7C31.8 -132.5 63.6 -114 104.6 -88.8",
                  "M104.1 -105.6C144.6 -63.6 193.8 -31.8 209.7 15.9C225.6 63.6 208.3 127.3 167.8 170.4C127.3 213.6 63.6 236.3 12.8 223.5C-37.9 210.6 -75.9 162.2 -110.9 119.1C-145.9 75.9 -177.9 37.9 -179.7 -1.8C-181.5 -41.5 -153 -83 -118 -125C-83 -167 -41.5 -209.5 -4.8 -204.7C31.8 -199.8 63.6 -147.6 104.1 -105.6",
                  "M106.3 -128.4C129.1 -83.4 133.1 -41.7 138.5 5.4C143.9 52.6 150.8 105.1 128 134.8C105.1 164.5 52.6 171.2 10.4 160.9C-31.8 150.5 -63.6 123 -89.5 93.3C-115.3 63.6 -135.2 31.8 -149.2 -14C-163.2 -59.9 -171.4 -119.7 -145.6 -164.7C-119.7 -209.7 -59.9 -239.9 -9.1 -230.8C41.7 -221.7 83.4 -173.4 106.3 -128.4",
                  "M159.1 -141.6C199.5 -118.8 220.7 -59.4 211.4 -9.3C202.1 40.8 162.2 81.6 121.9 107.7C81.6 133.9 40.8 145.4 4.5 141C-31.8 136.5 -63.6 116 -108.6 89.8C-153.6 63.6 -211.8 31.8 -221.8 -10C-231.9 -51.9 -193.7 -103.7 -148.7 -126.5C-103.7 -149.4 -51.9 -143.2 3.8 -147C59.4 -150.7 118.8 -164.5 159.1 -141.6",
                ],
              }}
              transition={{
                duration: 18,
                ease: "easeInOut",
                times: [0, 0.2, 0.4, 0.6, 0.8, 1],
                repeat: Infinity,
                repeatDelay: 1,
              }}
            ></motion.path>
          </g>
        </svg>
      </hero>
  );
}

export default Home;