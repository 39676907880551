import portfolioLOL from "../assets/PortfolioImage.png"
import todoLOL from "../assets/TodoImage.png"
import quizLOL from "../assets/QuizImage.png"
import colorAndGradientGeneratorLOL from "../assets/ColorAndGradientGeneratorImage.png"
import tenziesLOL from "../assets/TenziesImage.png"

export const TechnicalProjectsData = [
    {
      image: portfolioLOL,
      title: "My Portfolio Site",
      live: "/",
      code: "https://github.com/sanjay-karki",
    },
    {
      image: todoLOL,
      title: "TODO App",
      live: "https://sanjay-karki.github.io/todo-app/",
      code: "https://github.com/sanjay-karki/todo-app",
    },
    {
      image: quizLOL,
      title: "Quiz App",
      live: "https://sanjay-karki.github.io/quiz-app/",
      code: "https://github.com/sanjay-karki/quiz-app",
    },
    {
      image: colorAndGradientGeneratorLOL,
      title: "Color & Gradient Generator",
      live: "https://sanjaykarki-color-and-gradient-generator.vercel.app/",
      code: "https://github.com/sanjay-karki/color-and-gradient-generator-app",
    },
    {
      image: tenziesLOL,
      title: "Tenzies Game",
      live: "https://sanjay-karki.github.io/tenzies-app/",
      code: "https://github.com/sanjay-karki/tenzies-app",
    },
  ];

export const CreativeProjectsData = [
    {
      vidId: "_BY6dra58Kc",
      title: "Music Producer",
      projectTitle: "Ooh Yea",
    },
    {
      vidId: "wo6Z8Y5oTp4",
      title: "Actor",
      projectTitle: "Bujhne Lai",
    },
    {
      vidId: "NDKkuOuRwJw",
      title: "Cinematographer",
      projectTitle: "Viral Bhaidiyo",
    },
    {
      vidId: "H57Z67SYwhw",
      title: "Project Manager",
      projectTitle: "Kathmandu Bazar",
    },
    {
      vidId: "NekbVRuR6e0",
      title: "Project Manager",
      projectTitle: "Maya Gara",
    },
    {
      vidId: "MNOa8I7tEG4",
      title: "Music Producer",
      projectTitle: "Tori Rap",
    },
    {
      vidId: "nstw4jngABE",
      title: "Music Producer",
      projectTitle: "Sapana Haru Ferdai",
    },
    {
      vidId: "aRjLxjlDMxU",
      title: "Cinematographer",
      projectTitle: "Funtastic",
    },
    {
      vidId: "QlxM5GzfOvI",
      title: "Music Producer",
      projectTitle: "Mana Bhari",
    },
    {
      vidId: "FCzdapgkYuM",
      title: "Cinematographer",
      projectTitle: "'I See Fire' Choreography",
    },
    {
      vidId: "kLoqyk3FQqo",
      title: "Editor",
      projectTitle: "KYC Live",
    },
    {
      vidId: "YP0N40VI6Ko",
      title: "Music Producer",
      projectTitle: "Dukha",
    },
    {
      vidId: "05DlTqPxcxI",
      title: "Music Producer",
      projectTitle: "Sabai Thik Cha",
    },
    {
      vidId: "ms_zcpHcpV8",
      title: "Music Producer",
      projectTitle: "Huhaha",
    },
  ];