import React from 'react'
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import { IoSchoolSharp, IoBriefcaseSharp } from "react-icons/io5";
import { FaBookReader } from "react-icons/fa";
import { ImOffice } from "react-icons/im";
import UndrawSlantedOuterSpaceRed from "../assets/redundantSVGs/UndrawSlantedOuterSpaceRed.svg"
import UndrawSlantedOuterSpaceYellow from "../assets/redundantSVGs/UndrawSlantedOuterSpaceYellow.svg"
import UndrawFloatingBalloonRed from "../assets/redundantSVGs/UndrawFloatingBalloonRed.svg"
import UndrawFloatingBalloonYellow from "../assets/redundantSVGs/UndrawFloatingBalloonYellow.svg"
import UndrawProgrammingRed from "../assets/redundantSVGs/UndrawProgrammingRed.svg"
import UndrawProgrammingYellow from "../assets/redundantSVGs/UndrawProgrammingYellow.svg"

function About({toggleState, ObjectSVG}) {
  return (
    <section className='about--container'>
      <div className="centered--about-sectionTitle">
        <h1 className='about--sectionTitle'>EDUCATION</h1>
        <FaBookReader className="about--sectionIcon"/>
      </div>
      <VerticalTimeline className="verticalTimelineClass" lineColor={toggleState ? "#f6f8ff" : "#272d2d" }>
        <VerticalTimelineElement
          className="vertical-timeline-element--education"
          contentStyle={{ background: toggleState ? "#000" : "#fff", color: toggleState ? "#f6f8ff" : "#272d2d" }}
          contentArrowStyle={{ borderRight: toggleState ? "7px solid #f6f8ff" : "7px solid #272d2d" }}
          date="2013 - 2015"
          iconStyle={{ background: toggleState ? "#FCE100" : "#c41437", color: toggleState ? "#272d2d" : "#f6f8ff" }}
          icon={<IoSchoolSharp />}
        >
          <h3 className="vertical-timeline-element-title">
            Xavier International College
          </h3>
          <h4 className="vertical-timeline-element-subtitle">
            High School Diploma
          </h4>
          <p> Science</p>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--education"
          contentStyle={{ background: toggleState ? "#000" : "#fff", color: toggleState ? "#f6f8ff" : "#272d2d" }}
          contentArrowStyle={{ borderRight: toggleState ? "7px solid #f6f8ff" : "7px solid #272d2d" }}
          date="2015 - 2019"
          iconStyle={{ background: toggleState ? "#FCE100" : "#c41437", color: toggleState ? "#272d2d" : "#f6f8ff" }}
          icon={<IoSchoolSharp />}
        >
          <h3 className="vertical-timeline-element-title">
            Tribhuvan University, IOE, Pulchowk Campus
          </h3>

          <h4 className="vertical-timeline-element-subtitle">
            Bachelor's Degree
          </h4>

          <p> Computer Engineering</p>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--education"
          contentStyle={{ background: toggleState ? "#000" : "#fff", color: toggleState ? "#f6f8ff" : "#272d2d" }}
          contentArrowStyle={{ borderRight: toggleState ? "7px solid #f6f8ff" : "7px solid #272d2d" }}
          date="2022 - 2024"
          iconStyle={{ background: toggleState ? "#FCE100" : "#c41437", color: toggleState ? "#272d2d" : "#f6f8ff" }}
          icon={<IoSchoolSharp />}
        >
          <h3 className="vertical-timeline-element-title">
            Lincoln University College
          </h3>

          <h4 className="vertical-timeline-element-subtitle">
            Master's Degree
          </h4>

          <p>Business Administration</p>
        </VerticalTimelineElement>
      </VerticalTimeline>

      <div className="centered--about-sectionTitle">
        <h1 className='about--sectionTitle'>WORK</h1>
        <ImOffice className="about--sectionIcon"/>
      </div>
      <VerticalTimeline className="verticalTimelineClass" lineColor={toggleState ? "#f6f8ff" : "#272d2d" }>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: toggleState ? "#000" : "#fff", color: toggleState ? "#f6f8ff" : "#272d2d" }}
          contentArrowStyle={{ borderRight: toggleState ? "7px solid #f6f8ff" : "7px solid #272d2d" }}
          date="2015 - 2019"
          iconStyle={{ background: toggleState ? "#FCE100" : "#c41437", color: toggleState ? "#272d2d" : "#f6f8ff" }}
          icon={<IoBriefcaseSharp />}
        >
          <h3 className="vertical-timeline-element-title">
            Digital Content Manager
          </h3>
          <h4 className="vertical-timeline-element-subtitle">
            Beest Productions
          </h4>
          <p>Oversaw full online branding & digital presence of the company which includes copywriting, handling client interactions, managing social media platforms like Facebook, Twitter, etc. as well as YouTube channel management. Designed graphics like cover art, channel art, announcement posters & thumbnails</p>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: toggleState ? "#000" : "#fff", color: toggleState ? "#f6f8ff" : "#272d2d" }}
          contentArrowStyle={{ borderRight: toggleState ? "7px solid #f6f8ff" : "7px solid #272d2d" }}
          date="2020 - present"
          iconStyle={{ background: toggleState ? "#FCE100" : "#c41437", color: toggleState ? "#272d2d" : "#f6f8ff" }}
          icon={<IoBriefcaseSharp />}
        >
          <h3 className="vertical-timeline-element-title">
            Lead Project Coordinator
          </h3>
          <h4 className="vertical-timeline-element-subtitle">
            Beest Productions
          </h4>
          <p>Ensured quality assurance and undertook a wide array of work which includes planning, scheduling, directing, and executing content production tasks for maximum performance of projects
          </p>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: toggleState ? "#000" : "#fff", color: toggleState ? "#f6f8ff" : "#272d2d" }}
          contentArrowStyle={{ borderRight: toggleState ? "7px solid #f6f8ff" : "7px solid #272d2d" }}
          date="2022 - present"
          iconStyle={{ background: toggleState ? "#FCE100" : "#c41437", color: toggleState ? "#272d2d" : "#f6f8ff" }}
          icon={<IoBriefcaseSharp />}
        >
          <h3 className="vertical-timeline-element-title">
            Director of Digital Communications
          </h3>
          <h4 className="vertical-timeline-element-subtitle">
            The New Global Order (TNGO)
          </h4>
          <p>Developed and executed communications strategies, created graphic designs, and managed social media platforms of the think tank</p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: toggleState ? "#000" : "#fff", color: toggleState ? "#f6f8ff" : "#272d2d" }}
          contentArrowStyle={{ borderRight: toggleState ? "7px solid #f6f8ff" : "7px solid #272d2d" }}
          date="2023 - present"
          iconStyle={{ background: toggleState ? "#FCE100" : "#c41437", color: toggleState ? "#272d2d" : "#f6f8ff" }}
          icon={<IoBriefcaseSharp />}
        >
          <h3 className="vertical-timeline-element-title">
            Software Engineer
          </h3>
          <h4 className="vertical-timeline-element-subtitle">
            Danphe Software Labs
          </h4>
          <p>Front-end development. React, Next.js, Redux, Sass, etc.</p>
        </VerticalTimelineElement>
      </VerticalTimeline>
      {
        toggleState ? 
          <>
          {ObjectSVG(UndrawProgrammingYellow, 'redundantSVG3')}
          {ObjectSVG(UndrawSlantedOuterSpaceYellow, 'redundantSVG2')}
          {ObjectSVG(UndrawFloatingBalloonYellow, 'redundantSVG1')}
          </>
          : 
          <>
          {ObjectSVG(UndrawProgrammingRed, "redundantSVG3")}
          {ObjectSVG(UndrawSlantedOuterSpaceRed, "redundantSVG2")}
          {ObjectSVG(UndrawFloatingBalloonRed, "redundantSVG1")}
          </>
      }
    </section>
  )
}

export default About