import React from "react";
import { Link, useLocation } from "react-router-dom";
import saanuLOL from "../assets/saanuB&W.svg";
import { TbX } from "react-icons/tb";
import { HiMenuAlt3 } from "react-icons/hi";
import { BsSunFill, BsMoonStarsFill } from "react-icons/bs";

function MyNavbar({ toggleState, handleToggle }) {
  // BUG FIX
  // Code to close mobile menu as soon as the window size changes or when the window is maximized abrubtly without clicking TbX
  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }
  function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = React.useState(
      getWindowDimensions()
    );
    React.useEffect(() => {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }
      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }, []);
    return windowDimensions;
  }
  const { width } = useWindowDimensions();
  React.useEffect(() => {
    if (width > 768) {
      setIsMenuActive(false);
    }
  }, [width]);

  // MEAT AND POTATOES
  const [isMenuActive, setIsMenuActive] = React.useState(false);

  const location = useLocation();

  React.useEffect(() => {
    setIsMenuActive(false);
  }, [location]);

  const handleMenuToggle = () => {
    setIsMenuActive((prevState) => !prevState);
  };

  const navbarnav = document.querySelector(".nav--container");
  window.onscroll = () => {
    if (window.scrollY > 50) {
      navbarnav.classList.add("nav--container-active");
    } else {
      navbarnav.classList.remove("nav--container-active");
    }
  };

  const navLinks = [
    {
      name: "about",
      to: "/about",
      active: "about",
    },
    {
      name: "skills",
      to: "/skills",
      active: "skills",
    },
    {
      name: "projects",
      to: "/projects",
      active: "projects",
    },
    {
      name: "contact",
      to: "/contact",
      active: "contact",
    },
  ];
  const [linkActive, setLinkActive] = React.useState(
    location.pathname === "/"
      ? ""
      : location.pathname.slice(1, location.pathname.length)
  );

  return (
    <nav className="nav--container">
      <div className="nav--logoContainer">
        <Link
          to="/"
          className="nav--logo"
          onClick={() => setLinkActive("home")}
        >
          <img
            src={saanuLOL}
            alt="[SVG] My Avatar (site logo? maybe?)"
            title="Home"
            className="img--logo"
          />
        </Link>
      </div>
      <div className="nav--links" id={isMenuActive ? "menuOpen" : "menuClose"}>
        {navLinks.map((navLink) => (
          <Link
            to={navLink.to}
            className={
              navLink.active === linkActive
                ? "nav--link nav--link-active"
                : navLink.active === "projects"
                ? "nav--link projects--button"
                : "nav--link"
            }
            onClick={() => setLinkActive(navLink.active)}
          >
            {navLink.name}
          </Link>
        ))}
      </div>
      <div id="themeBtnContainer">
        {toggleState ? (
          <BsSunFill
            onClick={handleToggle}
            className="theme--toggleIcon"
            title="Toggle Light Mode"
          />
        ) : (
          <BsMoonStarsFill
            onClick={handleToggle}
            className="theme--toggleIcon"
            title="Toggle Dark Mode"
          />
        )}
      </div>
      <div id="mobile--menu">
        {isMenuActive ? (
          <TbX onClick={handleMenuToggle} className="menu--toggleIcon" />
        ) : (
          <HiMenuAlt3 onClick={handleMenuToggle} className="menu--toggleIcon" />
        )}
      </div>
    </nav>
  );
}

export default MyNavbar;
