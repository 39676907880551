import React from "react";
import {
  FaFacebookF,
  FaInstagram,
  FaLinkedin,
  FaTwitter,
  FaGithub,
} from "react-icons/fa";

function Footer() {
  const footerIcons = [
    {
      icon: FaFacebookF,
      iconHref: "https://www.facebook.com/sonj.karki",
      iconTitle: "My Facebook",
    },
    {
      icon: FaInstagram,
      iconHref: "https://www.instagram.com/sanjay_karki9",
      iconTitle: "My Instagram",
    },
    {
      icon: FaGithub,
      iconHref: "https://github.com/sanjay-karki",
      iconTitle: "My GitHub",
    },
    {
      icon: FaLinkedin,
      iconHref: "https://www.linkedin.com/in/sanjay-karki9",
      iconTitle: "My LinkedIn",
    },
    {
      icon: FaTwitter,
      iconHref: "https://twitter.com/sanjay_karki9",
      iconTitle: "My Twitter",
    },
  ];
  return (
    <section className="footer--container">
      <div className="footer--iconsContainer">
        {footerIcons.map((EachIcon) => {
          const Icon = EachIcon.icon;
          const Href = EachIcon.iconHref;
          const Title = EachIcon.iconTitle;
          return (
            <a href={Href} target="_blank" rel="noreferrer">
              <Icon title={Title} size={23} />
            </a>
          );
        })}
      </div>
      <div className="footer--spanTextContainer">
        <span>
          Made with enthusiasm by Sanjay Karki
          <p>&copy; 2022</p>
        </span>
      </div>
    </section>
  );
}

export default Footer;
