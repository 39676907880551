import React from "react";
import { YouTube } from 'react-youtube-lazyload'
import 'react-youtube-lazyload/dist/index.css'
import { CreativeProjectsData } from "./ProjectsItemData";
import UndrawVideoFilesRed from "../assets/redundantSVGs/UndrawVideoFilesRed.svg"
import UndrawVideoFilesYellow from "../assets/redundantSVGs/UndrawVideoFilesYellow.svg"
import UndrawImageAndTreeRed from "../assets/redundantSVGs/UndrawImageAndTreeRed.svg"
import UndrawImageAndTreeYellow from "../assets/redundantSVGs/UndrawImageAndTreeYellow.svg"

function CreativeProjects({toggleState, ObjectSVG}) {
  return (
    <div className="projects--creativeContainer" id="creative">
      {CreativeProjectsData.map((ProjectItem) => (
        <div className="singleProject--creativeContainer">
          <div className="projectVideo">
            <YouTube
              videoId={ProjectItem.vidId}
              privacy={true}
              width= {340}
              height= {340 * (9 / 16)}
            />
          </div>
          <h2>{ProjectItem.title}</h2>
          <p>{ProjectItem.projectTitle}</p>
        </div>
      ))}
      {
        toggleState ? 
          <>
          {ObjectSVG(UndrawImageAndTreeYellow, 'redundantSVG2')}
          {ObjectSVG(UndrawVideoFilesYellow, 'redundantSVG1')}
          </>
          : 
          <>
          {ObjectSVG(UndrawImageAndTreeRed, "redundantSVG2")}
          {ObjectSVG(UndrawVideoFilesRed, "redundantSVG1")}
          </>
      }
    </div>
  );
}

export default CreativeProjects;
