import './dist/css/app.css';
import React from 'react';
import {BrowserRouter as Router, Routes, Route } from "react-router-dom"
import Navbar from './Components/Navbar'
import Footer from './Components/Footer'
import { FaAngleUp } from "react-icons/fa";
import Home from './Pages/Home';
import About from './Pages/About';
import Skills from './Pages/Skills';
import Projects from './Pages/Projects';
import Contact from './Pages/Contact';
import Error from './Pages/Error';

function App() {
  // THEME TOGGLE
  const [toggleState, setToggleState] = React.useState(true);
  const handleToggle = () => {
    setToggleState(prevState => !prevState)
  }

  // SCROLL TO TOP
  const [showTopBtn, setShowTopBtn] = React.useState(false);
  React.useEffect(() => {
    window.addEventListener("scroll", () => {
        if (window.scrollY > 400) {
            setShowTopBtn(true);
        } else {
            setShowTopBtn(false);
        }
    });
  }, []);
  const goToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: "smooth",
    });
  };

  // Redundant BG SVGs
  const ObjectSVG = (RedundantSVG, RedundantClassName) => {
    return (
      <object className={RedundantClassName} data={RedundantSVG} type="image/svg+xml">
        <img src={RedundantSVG} alt="" className={RedundantClassName}  />
      </object> 
    )
  }

  return (
    <>
      <main className={toggleState ? 'darkMode' : ''}>
      <Router>
        <Navbar toggleState={toggleState} handleToggle={handleToggle} />
        <Routes>
          <Route path="/" element={<Home toggleState={toggleState} />} />
		  <Route path="home" element={<Home toggleState={toggleState} />} />
          <Route path="about" element={<About toggleState={toggleState} ObjectSVG={ObjectSVG} />} />
          <Route path="skills" element={<Skills toggleState={toggleState} ObjectSVG={ObjectSVG} />} />
          <Route path="projects" element={<Projects toggleState={toggleState} ObjectSVG={ObjectSVG} />} />
          <Route path="contact" element={<Contact toggleState={toggleState} ObjectSVG={ObjectSVG} />} />
          <Route path="*" element={<Error />} />
        </Routes>
        {showTopBtn && (
          <FaAngleUp
              className="scrollUp--button"
              onClick={goToTop}
          />
        )}
        <Footer />
      </Router>
    </main>
    </>
  );
}

export default App;
