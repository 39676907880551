import React from "react";
import TechnicalProjects from "../Components/TechnicalProjects";
import CreativeProjects from "../Components/CreativeProjects";

function Projects({toggleState, ObjectSVG}) {
  const [isButtActive, setIsButtActive] = React.useState(true);

  return (
    <section className="projects--container">
      <button
        className={
          isButtActive
            ? "projects--mainButton projects--mainButton-active"
            : "projects--mainButton"
        }
        onClick={() => setIsButtActive(true)}
      >
        WEB
      </button>
      <button
        className={
          !isButtActive
            ? "projects--mainButton projects--mainButton-active"
            : "projects--mainButton"
        }
        onClick={() => setIsButtActive(false)}
      >
        CREATIVE
      </button>
      {isButtActive ? <TechnicalProjects toggleState={toggleState} ObjectSVG={ObjectSVG} /> : <CreativeProjects toggleState={toggleState} ObjectSVG={ObjectSVG} />}
    </section>
  );
}

export default Projects;
