import React from 'react'
import {Link} from 'react-router-dom'
import { TbFaceIdError } from "react-icons/tb";

function Error() {
  return (
    <section className='error--container'>
      <h1>Error 404</h1>
      <p>Page not found</p><br />
      <TbFaceIdError className='error--reactIcon' /><br />
      <Link to="/" className='error--backLink'>Go back to Home Page</Link>
    </section>
  )
}

export default Error